<template>
  <div class="chat-messages">
    <div class="chat-messages__header">
      <p class="status" :class="status.class">{{ status.text }}</p>
    </div>

    <div class="messages-area" ref="messagesArea">
      <div
          v-for="itm in items"
          :key="itm.id"
          :class="[
					'message',
					itm.is_admin ? 'message__user--first' : 'message__user--second'
				]"
      >
        <div class="message__text">
          {{ itm.text }}
        </div>

        <tk-image
            v-if="itm.image_data"
            width="100"
            height="100"
            class="message__image"
            :src="itm.image_data"
        />

        <div class="time">
          <span>{{ getDateTime(itm.created_at) }}</span>
          <!-- <div :class="['img', itm.is_viewed ? 'active' : '']">
            <img src="@/assets/images/svg/message-sended.svg" alt="" />
          </div> -->
        </div>
      </div>

			<div class="write-message">
        <div class="write-message__file" v-if="file">
          Вложение: {{ file.name }}
        </div>

				<div class="write-message__input-wrapper">
					<tk-input
						v-model.trim="text"
						class="chat-message-input"
						placeholder="Введите сообщение"
						:is-clear-button="false"
            @keydown.enter.stop="sendMsg"
					/>
				</div>

        <label class="write-message__upload">
          <tk-svg type="upload"/>
          <input
              type="file"
              @change="setFile"
              accept=".png, .jpg, .jpeg"
              hidden
          />
        </label>

        <button
            class="btn write-message__submit"
            :disabled="!text.length"
            @click="sendMsg"
        >
          <tk-svg type="message-send"/>
        </button>
      </div>
      <div class="write-message__background"></div>
    </div>
  </div>
</template>

<script>
import ticketKeys from '@/constants/tickets'
import {getDateInFormat} from '@/utils/dateTime'
import {nextTick} from "vue";

export default {
  name: 'FeedbackChat',
  watch: {
    page: async function () {
      await this.getData()
    },
    items: function () {
      if (this.$refs.messagesArea) {
        const isScrollUpdate = this.isUpdate

        setTimeout(() => {
          let scrollTop = this.$refs.messagesArea?.scrollHeight

          if (isScrollUpdate) {
            scrollTop =
                this.$refs.messagesArea?.scrollHeight - this.preventScroll
          }

          this.$refs.messagesArea.scrollTop = scrollTop
        })

        this.isUpdate = false
      }
    }
  },
  data() {
    return {
      text: '',
      isUpdate: false,
      preventScroll: 0,
      limit: 10,
      page: 1,
      file: null,
      meta: {},
      items: []
    }
  },
  computed: {
    status() {
      return ticketKeys[this.$route.params.status || 1]
    }
  },
  methods: {
    async sendMsg() {
      const response = await this.$api.tickets.createItem({
        theme_id: this.$route.params.theme_id,
        ticket_id: this.$route.params.tickets_id,
        payload: {message: this.text, image: this.file}
      })

      if (response?.status) {
        await this.getData()
        this.text = ''
        this.file = null
			}
		},
		setFile(evt) {
      const file = evt.target.files[0]
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.$store.commit('showNotification', {
          type: 'error',
          text: "Прикреплять к сообщению можно только картинки форматов png, jpeg"
        })
        return
      }
			this.file = file
		},
		getDateTime(payload) {
			const date = new Date(payload)
			const time =
				('0' + date.getHours()).slice(-2) +
				':' +
				('0' + date.getMinutes()).slice(-2)

      return `${getDateInFormat(payload)} ${time}`
    },
    async getData() {
      const response = await this.$api.tickets.getMessages({
        // page: this.page,
        // limit: this.limit,
        ticket_id: this.$route.params.tickets_id
      })

      if (!response.status) {
        return
      }

      this.items = response.response.items
      this.items.reverse()

      this.meta = response.response.meta

      await nextTick()
      if (this.$refs.messagesArea) {
        this.$refs.messagesArea.scrollTop = this.$refs.messagesArea?.scrollHeight
      }
    },
    scroll(evt) {
      // if (this.$refs.messagesArea?.scrollTop < 1) {
      // 	this.isUpdate = true
      // 	this.preventScroll = this.$refs.messagesArea?.scrollHeight
      // 	// Handle count values
      // 	this.page += 1
      // }
    }
  },
  created() {
    if (!this.$route.params.tickets_id) {
      this.$router.back()

      return
    }
  },
  async mounted() {
    await this.getData()
  }
}
</script>

<style lang="sass" scoped>
.chat-messages
  &__header
    display: flex
    justify-content: center
    margin-bottom: 0
    border: 1px solid #E2E2E2
    border-radius: 20px 20px 0px 0px

  .messages-area
    margin-top: 0
    border-radius: 0px 0px 20px 20px
</style>

<style lang="sass" scoped>
.chat-messages
  max-height: 768px
  width: 100%
  position: relative
  // .backbutton
  //   visibility: hidden
  &__header
    display: flex
    align-items: center
    height: 83px
    padding: 11px 25px 10px 25px
    background: #FFFFFF
    border: 1px solid #E2E2E2
    border-left: none
    box-sizing: border-box
    border-radius: 0 20px 0px 0px

    &_img
      margin-right: 10px
      border-radius: 50%
      overflow: hidden

    &_text
      .last-visit
        font-size: 12px
        line-height: 12px
        color: #CBCBCB
        margin-bottom: 5px
      .username
        font-weight: 600
        font-size: 14px
        line-height: 14px
        color: #3B3F47

    &_block
      display: flex
      align-items: center
      margin-left: auto
      margin-right: 25px

      .block-img
        margin-right: 5px

      .block-text
        font-size: 12px
        line-height: 12px
        color: #E93030

      &:hover
        cursor: pointer

        .block-text
          text-decoration: underline

    &_call
      transition: 0.2s linear

      &:hover
        cursor: pointer
        transition: 0.2s linear
        transform: scale(1.1) rotate(60deg)

  .messages-area
    overflow-y: auto
    // position: relative
    background: #F5F5F5
    padding-top: 20px
    padding-bottom: 86px
    // padding-bottom: 18px
    padding-left: 28px
    padding-right: 28px
    height: 100%
    max-height: 686px
    border-radius: 0px 0px 20px 0
    display: flex
    flex-grow: 1
    flex-direction: column
    // justify-content: flex-end
    .message
      word-break: break-all
      padding: 17px 17px 5px 15px
      width: 80%
      margin-bottom: 20px
      font-size: 16px
      line-height: 18px

      &__image
        height: auto

      .time
        display: flex
        opacity: 0.2
        font-size: 11px
        line-height: 13px
        margin-top: 22px
        color: #000000

        span
          margin-right: 3px

        .img
          position: relative

          &:before
            // content: url('~@/assets/images/svg/message-delivered.svg')
            position: absolute
            right: -3px
            bottom: -2px
            opacity: 1

      &__user--first
        position: relative
        align-self: flex-end
        background: #ffffff
        border-radius: 10px 10px 0px 10px
        color: #000000

        &:before
          content: ''
          position: absolute
          width: 13px
          height: 18px
          bottom: 0
          right: -12px
          background: radial-gradient(180% 180% at right -65% top -65%, transparent 99%, #ffffff)

        .time
          justify-content: flex-end

      &__user--second
        position: relative
        background: #1AC386
        align-self: flex-start
        border-radius: 10px 10px 10px 0px
        color: #FFFFFF

        &:before
          content: ''
          position: absolute
          width: 13px
          height: 18px
          bottom: 0
          left: -12px
          background: radial-gradient(180% 180% at left -65% top -65%, transparent 99%, #1AC386)

        .time
          justify-content: flex-start

    .write-message
      position: absolute
      bottom: 25px
      z-index: 10
      width: 94%
      display: flex
      justify-content: space-between
      align-items: center
      &__file
        font-size: 14px
        line-height: 1
        position: absolute
        white-space: nowrap
        bottom: -20px
        left: 12px
        color: grey
        text-overflow: ellipsis
        overflow: hidden
        max-width: 90%
      &__input-wrapper
        width: 100%
        margin-right: 25px

      &__upload
        display: flex
        justify-content: center
        align-items: center
        width: 60px
        min-width: 60px
        height: 60px
        margin-right: 15px
        background: #5F5350
        border-radius: 12px
        cursor: pointer

      &__submit
        width: 60px
        min-width: 60px
        height: 60px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 12px
        border: none
        background-color: $green
        box-shadow: 0px 5px 15px rgba(95, 83, 80, 0.3)

    .write-message__background
      position: absolute
      bottom: -1px
      width: 98%
      left: 0
      height: 85px
      background: #F5F5F5
      z-index: 5
      border-radius: 0px 0px 20px 20px


@media screen and (max-width: 1180px)
  .chat-messages
    // width: 396px
    .messages-area
      .message
        font-size: 15px

        .time
          margin-top: 10px

@media screen and (max-width: 1024px)
  .chat-messages
    // width: 370px
    .messages-area
      .message
        font-size: 14px
        .time
          margin-top: 5px

@media screen and (max-width: 1000px)
  .chat-messages
    .messages-area
      .write-message
        width: 89%
    &__header
      border-left: 1px solid #E2E2E2
      border-radius: 20px 20px 0px 0px

    .messages-area
      border-radius: 0px 0px 20px 20px

@media screen and (max-width: 800px)
  .chat-messages
    .messages-area
      .write-message
        &__file
          font-size: 12px
        &__upload,
        &__submit
          width: 40px
          min-width: 40px
          height: 40px
@media screen and (max-width: 479px)
  .chat-messages
    .messages-area
      .write-message
        width: 80%
        &__input-wrapper
          margin-right: 15px
        &__upload
          margin-right: 10px
</style>
